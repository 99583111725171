#pnfWrapper {
    .pnfWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: $max-width;
        margin: 0 auto;
        height: 85vh;
        font-family: $primary-font;
        img.notFoundImage {
            width: 25rem;
            aspect-ratio: auto 1 / 1;
        }
        .pnfTitle {
            color:$thistle;
            font-size: 8rem;
            text-align: center;
            margin-bottom: 1rem;
            margin-top: 1rem;
        }
        .pnfdescription {
            color:$black;
            font-size: 1.6rem;
            text-align: center;
            margin-bottom: 6rem;
        }
        .pnfLink {
            text-decoration: none;
            background: $primary-color;
            color: white;
            padding: 0.8rem 2rem;
            font-weight: 500;
            font-size: 1.2rem;
            transition: 0.1s linear;
            &:hover {
                background: $off-white;
                color: $primary-color;
                padding: 0.8rem 2rem;
                letter-spacing: 2px;
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    #pnfWrapper {
        .pnfWrapper {
            width: 80%;
            height: 85vh;
            font-family: $primary-font;
            img.notFoundImage {
                width: 15rem;
                aspect-ratio: auto 1 / 1;
            }
            .pnfTitle {
                font-size: 4rem;
            }
            .pnfdescription {
                font-size: 1rem;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    #pnfWrapper {
        .pnfWrapper {
            width: 90%;
            height: 65vh;
            font-family: $primary-font;
            .pnfTitle {
                font-size: 2rem;
            }

            .pnfLink {
                font-weight: 400;
                font-size: 1.2rem;
            }
        }
    }
}