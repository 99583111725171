footer {
    // background-color: #ffffff;
    // box-shadow:0px -5px 20px rgba($color: #363636, $alpha: 0.1);
    margin-top: 1rem;
    .footer {
        font-family: $secondary-font;
        max-width: $max-width-retina;
        margin: 0 auto;
        padding: 4rem 0px 0px 0px;

        .contact-me {
            font-family: $primary-font;
            font-size: 1.8rem;
            font-weight: 500;
            margin-bottom: 4rem;
            span {
                color: $accent-color;
            }
        }

        ul.social-links {
            padding: 0px;
            display: flex;
            flex-direction: column;
            margin: 0px;
            width: max-content;

            &:hover {
                li {
                    transition: 0.1s linear;
                    opacity: 0.5;
                    // filter: blur(1px);
                    filter: grayscale(1);

                    &:hover {
                        transition: 0.1s linear;
                        opacity: 1;
                        // filter: blur(0px);
                        filter: grayscale(0);
                    }
                }
            }

            li {
                flex-direction: row;
                text-decoration: none;
                list-style: none;
                cursor: pointer;

                img {
                    width: 35px;
                    height: 35px;
                    margin-right: 15px;
                    border-radius: 100px;
                    box-shadow: 0px 0px 15px rgba($color: #000000, $alpha: 0.075);
                }

                a {
                    font-family: $primary-font;
                    text-decoration: none;
                    font-size: 1.4rem;
                    display: flex;
                    align-items: center;
                    margin: 1rem 2rem 1rem 0rem;
                }
            }

            li.mail {
                a {
                    color: #0D31EE
                }
            }

            li.dribbble {
                a {
                    color: #FC37A1
                }
            }

            li.linkedin {
                a {
                    color: #26A9D2
                }
            }

            li.instagram {
                a {
                    color: #DE5134
                }
            }
        }

        p.footerstripe {
            margin: 0px;
            padding: 1.6rem 0px;
            text-align: right;
            // font-family: $secondary-font;
            font-family: $primary-font;
            font-size: 0.8rem;

            span {
                opacity: 0.8;
                // color: $accent-color;
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    footer {
        .footer {
            max-width: $max-width-regular;
            .contact-me {
                font-size: 1.2rem;
            }
            ul.social-links {
                li {
                    img {
                        width:30px;
                        height:30px;
                        margin-right: 10px;
                    }
                    a {
                        font-size: 1rem;
                        margin: 1rem 1rem 1rem 0rem;
                    }
                }
            }

            p.footerstripe {
                font-size: 0.6rem;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    footer {
        .footer {
            font-family: $secondary-font;
            width: 90%;
            padding: 0px;

            .contact-me {
                font-family: $primary-font;
                font-size: 1rem;
                margin-bottom: 1rem;
                opacity: 0.5;
            }

            ul.social-links {
                &:hover {
                    li {
                        transition: 0.1s linear;
                        opacity: 1;
                        filter: none;
                    }
                }

                li {
                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }

                    a {
                        font-family: $primary-font;
                        text-decoration: none;
                        font-size: 0.8rem;
                        margin: 1rem 1rem 0rem 0rem;
                    }
                }
            }

            p.footerstripe {
                margin: 0px;
                padding: 1.6rem 0px;
                text-align: center;
                font-family: $secondary-font;
                font-size: 0.6rem;

                span {
                    opacity: 0.8;
                }
            }
        }
    }
}