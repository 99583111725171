@import "./variables.scss";


@import "./dark.scss";
@import "./light.scss";
@import "./header.scss";
@import "./hero.scss";
@import "./blog.scss";
@import "./resume.scss";
@import "./pageNotFound.scss";
@import "./selectedWork.scss";
@import "./experience.scss";
@import "./workWith.scss";
@import "./someSkills.scss";
@import "./caseStudy.scss";
@import "./footer.scss";

::-webkit-scrollbar {
    display: none;
}

::selection {
    color: #ffffff;
    background: $primary-color;
}
.relative {
    position: relative;
}
.absolute {
    position: absolute;
}
html {
    background-image: url("../assets/img/bg/background/grain.png");
    background-size: 300px 300px;
    background-repeat:repeat;
    background-attachment: fixed;
    body {
        // position: relative;
        .AppWrapper {
            background-image: url("../assets/img/bg/background.png");
            background-size: 100%;
            background-repeat:no-repeat;
            background-attachment: fixed;
        }
        #homeWrapper,
        #aboutWrapper,
        #case-study,
        #blogWrapper,
        #resumeWrap,
        #work {
            background-size: cover;
            background-size: 100%;
            background-repeat: no-repeat;
        }
    }
    .toggleTheme {
        display: none;
        position: fixed;
        bottom: 2rem;
        right: 2rem;
        height: 3.4rem;
        width: 3.4rem;
        border: none;
        border-radius: 3rem;
        cursor: pointer;
        // display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 1.6rem;
            height: 1.6rem;
        }
    }
    .cursor {
        background-color: rgba($accent-color, $alpha: 0.1);
        height: 35px;
        width: 35px;
        border-radius: 50%;
        position: fixed;
        top: -19px;
        left: -18px;
        pointer-events: none;
        backdrop-filter: blur(2px);
    
        .point {
            background-color: rgba($accent-color, $alpha: 1);
            height: 4px;
            width: 4px;
            border-radius: 50%;
            position: fixed;
            top: 15px;
            left: 15px;
        }
    }

    // .floatNextProject {
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     background-color: #ffffff;
    //     position: fixed;
    //     bottom: 2vh;
    //     right: 20%;
    //     width: $max-width;
    //     height: 4rem;
    //     border-radius: 10rem;
    //     box-shadow: 0px 10px 25px rgba($color: #000000, $alpha: 0.15);
    //     font-family: $primary-font;
    //     font-size: 1rem;
    
    //     div {
    //         padding: 0rem 2rem;
    //     }
    // }
    
    // .backButton {
    //     font-family: $primary-font;
    //     font-size: 2rem;
    //     color: $accent-color;
    // }
}



#aboutWrapper {
    p.social-links-title {
        font-family: $primary-font;
        margin-bottom: 10px;
        font-size: 1.4rem;
    }

    .social-links-wrapper {
        display: flex;

        ul.social-links {
            padding: 0px;
            display: flex;
            margin: 0px;
            width: auto;

            &:hover {
                li {
                    transition: 0.1s linear;
                    opacity: 0.5;

                    &:hover {
                        transition: 0.1s linear;
                        opacity: 1;
                    }
                }
            }

            li {
                flex-direction: row;
                text-decoration: none;
                list-style: none;
                padding-right: 2rem;

                img {
                    width: 25px;
                    margin-right: 5px;
                }

                a {
                    font-family: $secondary-font;
                    text-decoration: none;
                    font-size: 1.2rem;
                    display: flex;
                }
            }

            li.mail {
                a {
                    color: #0d31ee;
                }
            }

            li.dribbble {
                a {
                    color: #fc37a1;
                }
            }

            li.linkedin {
                a {
                    color: #26a9d2;
                }
            }

            li.instagram {
                a {
                    color: #de5134;
                }
            }
        }
    }
}
.moreWorkContainer {
    max-width: $max-width-retina;
    margin: 0 auto;
    padding-bottom: 2rem;
    .view-more-work {
        display: flex;
        justify-content: flex-end;
        p {
          font-family: $primary-font;
          color: #000000;
          font-size: 1.2rem;
          text-align: right;
          font-weight: 400;
          span{
            background: $accent-color;
            color: white;
            padding: 0.9rem 1.2rem;
            margin-left: 0.8rem;
            font-size: 1.1rem;
            transition: 0.1s linear;
            &:hover {
                background: $off-white;
                color: $accent-color;
                padding: 0.8rem 2rem;
                letter-spacing: 2px;
            }
          }
        }
        a {
          text-decoration: none;
        }
      }
}

@media screen and (max-width: 1440px) {
    .cursor {
        display: none;

        .point {
            display: none;
        }
    }

    .moreWorkContainer {
        max-width: $max-width-regular;
        .view-more-work {
            p {
              font-family: $primary-font;
              color: #000000;
              font-size: 0.9rem;
              text-align: center;
              line-height: 3rem;
              span{
                padding: 0.8rem;
                margin-left: 0.6rem;
                font-size: 0.8rem;
                &:hover {
                    background: $accent-color;
                    color: white;
                    padding: 0.8rem;
                    letter-spacing: 0px;
                }
              }
            }
          }
    }
}

@media screen and (max-width: 768px) {
    .cursor {
        display: none;

        .point {
            display: none;
        }
    }

    .moreWorkContainer {
        max-width: 90%;
        .view-more-work {
            p {
              font-family: $primary-font;
              color: #000000;
              font-size: 0.9rem;
              text-align: center;
              line-height: 3rem;
              span{
                padding: 0.8rem;
                margin-left: 0.6rem;
                font-size: 0.8rem;
                &:hover {
                    background: $accent-color;
                    color: white;
                    padding: 0.8rem;
                    letter-spacing: 0px;
                }
              }
            }
          }
    }
}