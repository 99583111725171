#experience.main-wrapper {
    max-width: $max-width-retina;
    margin: 0 auto;
    padding-bottom: 3rem;

    p.title {
        font-family: $primary-font;
        font-weight: 500;
        font-size: 1.4rem;
        padding-right: 10%;
        margin-bottom: 60px;

        span {
            opacity: 1;
            // padding-top: -1004px;
        }
    }

    .experienceWrapper {
        padding-bottom: 3rem;

        hr {
            opacity: 0.75;
        }

        .titleWrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;

            p.title,
            p.titlesno {
                font-family: $secondary-font;
                font-size: 1.6rem;
                margin: 0px;
            }

            p.title,
            p.titlesno {
                font-family: $primary-font;
                color: $accent-color;
                font-weight: 500;
                font-size: 1.4rem;
                margin-left: 10px;
            }
        }

        .experienceDetailsWrapper {
            p {
                margin: 0px;
                font-family: $secondary-font;
                margin-left: 10px;
            }

            p.position {
                font-weight: bold;
                font-size: 1.1rem;
                margin-bottom: 10px;
            }

            p.period {
                font-size: 0.8rem;
                margin-bottom: 10px;
            }

            p.whatidid {
                font-size: 1.1rem;
                opacity: 0.5;
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    #experience.main-wrapper {
        max-width: $max-width-regular;
    
        p.title {
            font-size: 1.2rem;
            margin-bottom: 30px;
        }
    
        .experienceWrapper {
            padding-bottom: 3rem;
    
            .titleWrapper {
                p.title,
                p.titlesno {
                    font-size: 1.2rem;
                }
            }
    
            .experienceDetailsWrapper {

                p.position {
                    font-weight: bold;
                    font-size: 1rem;
                    margin-bottom: 10px;
                }
                p.whatidid {
                    font-size: 0.9rem;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #experience.main-wrapper {
        width: 90%;

        p.title {
            font-size: 1rem;
            padding-right: 10%;
            margin-bottom: 2rem;
        }

        .experienceWrapper {
            padding-bottom: 3rem;

            hr {
                opacity: 0.75;
            }

            .titleWrapper {

                p.title,
                p.titlesno {
                    font-size: 1rem;
                }
            }

            .experienceDetailsWrapper {
                p.position {
                    font-size: 1rem;
                }

                p.whatidid {
                    font-size: 1rem;
                }
            }
        }
    }
}