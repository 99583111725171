#resumeWrap {
  hr {
    height: 1px;
    background-color: $black;
    opacity: 0.25;
  }
  .resume {
    margin: 3rem 0rem 6rem 0rem;
    .resumeWrapper {
      margin-top: 4rem;
      max-width: $max-width-retina;
      margin: 0 auto;
      padding: 3rem;
      font-family: $resume-font;
      background-color: #d8d3ce;
      border-radius: 1rem;
      .introWrapper {
        .name {
          font-size: 4em;
          font-weight: 300;
          margin: 0;
          margin-top: 2rem;
        }
        .post {
          font-size: 2rem;
          font-weight: 300;
          margin: 1rem;
          margin-left: 4px;
          opacity: 0.5;
        }
      }
      .description {
        font-size: 1.8rem;
        font-weight: 300;
      }
      .linkWrapper {
        display: flex;
        flex-direction: row;
        margin: 2rem 0rem 1rem 0rem;
        a {
          text-decoration: none;
          margin-right: 2rem;
          font-family: $resume-font;
          color: $black;
          font-size: 1.4rem;
          opacity: 0.35;
          transition: 0.5s linear;
          &:hover {
            // text-decoration: underline;
            opacity: 1;
          }
        }
      }
      .bottomWrapper {
        .workWrapper {
          display: flex;
          flex-direction: row;
          h2 {
            font-size: 2rem;
            font-weight: 400;
          }
          .description {
            font-size: 1.2rem;
            font-weight: 300;
          }
          .flexxer {
            padding-right: 2rem;
            &:nth-child(1) {
              flex: 50%;
            }
            &:nth-child(2) {
              flex: 25%;
            }
            &:nth-child(3) {
              flex: 25%;
            }
          }
          .resumeExperience {
            margin-bottom: 2rem;
            .companyName {
              font-size: 1.4rem;
              font-weight: 300;
              margin: 0;
              margin-bottom: 0.4rem;
            }
            .timeline {
              font-size: 1rem;
              font-weight: 300;
              margin: 0;
              margin-bottom: 0.4rem;
              opacity: 0.75;
            }
            .description {
              font-size: 1.1rem;
              font-weight: 300;
              margin: 0;
            }
          }
          .resumeEducation {
            margin-bottom: 2rem;
            .collegeName {
              font-size: 1.4rem;
              font-weight: 300;
              margin: 0;
              margin-bottom: 0.4rem;
            }
            .timeline {
              font-size: 1rem;
              font-weight: 300;
              margin: 0;
              margin-bottom: 0.4rem;
              opacity: 0.75;
            }
            .description {
              font-size: 1.1rem;
              font-weight: 300;
              margin: 0;
            }
          }
          .resumeSkills {
            margin-bottom: 2rem;
            .skillname {
              font-size: 1.4rem;
              font-weight: 300;
              margin: 0;
              margin-bottom: 0.4rem;
            }
            .skilllist {
              font-size: 1.2rem;
              font-weight: 300;
              margin: 0;
              opacity: 0.75;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  #resumeWrap {
    .resume {
      .resumeWrapper {
        max-width: $max-width-regular;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  #resumeWrap {
    .resume {
      .resumeWrapper {
        max-width: $max-width-mobile;
        border-radius: 0;
        background-color: $off-white;
        .linkWrapper {
          display: flex;
          flex-direction: column;
          a {
            line-height: 2.6rem;
            opacity: 0.75;
          }
        }
        .bottomWrapper {
          .workWrapper {
            flex-direction: column;
          }
        }
      }
    }
  }
}
