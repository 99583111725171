#hero,
#heroAbout {
    max-width: $max-width-retina;
    margin: 0 auto;
    padding-top: 15vh;
    padding-bottom: 4vh;
    p.resumeWrap {
        display: none;
        text-align: center;
        padding: 1rem 0px;
        a.resume {
            font-family: $primary-font;
            font-weight: 500;
            background-color: $accent-color;
            color: white;
            padding: 10px 25px;
            transition: 0.1s linear;
            border: 2px solid $accent-color;
            text-decoration: none;
            text-align: center;
            &:hover {
              background-color: #000000;
              background-color: $accent-color;
              opacity: 1;
              color: white;
              border: 2px solid transparent;
            }
          }
    }

    .hello {
        font-size: $hero-hello-3X;
        font-family: $primary-font;
        font-weight: 500;
        letter-spacing: 1px;
        color: $accent-color;
        text-align: center;
    }

    h1.title {
        width: 100%;
        font-size: $hero-title-3X;
        font-family: $primary-font;
        margin: 0;
        font-weight: 800;
        text-align: center;

        span {
            color: $primary-color;
        }

        span.emoji {
            font-size: $hero-title-3X;
        }
    }

    p.desc {
        padding: 0 5%;
        text-align: center;
        font-family: $secondary-font;
        font-size: $hero-desc-3X;
        color: rgba($color: #000000, $alpha: 0.5);
    }
}

@media screen and (max-width: 1440px) {
    #hero,
    #heroAbout {
        max-width: $max-width-regular;
        padding-top: 10vh;
        .hello {
            font-size: $hero-hello-2X;
            font-weight: 500;
        }

        h1.title {
            font-size: $hero-title-2X;
            width: 100%;
            font-weight: 800;

            span.emoji {
                font-size: $hero-title-2X;
            }
        }

        p.desc {
            padding: 0 5%;
            font-size: $hero-desc-2X;
        }
    }
}

@media screen and (max-width: 768px) {
    #hero,
    #heroAbout {
        margin: 0 auto;
        padding-bottom: 2vh;
        padding-top: 10vh;
        width: 90%;
        p.resumeWrap {
            display: block;
        }
        .hello {
            font-size: $hero-hello-1X;
            font-weight: 500;
        }

        h1.title {
            font-size: $hero-title-1X;
            font-weight: 700;

            span.emoji {
                font-size: 1.4rem;
            }
        }

        p.desc {
            font-size: $hero-desc-1X;
            padding: 0;
        }
    }
}