#selectedWork {
  max-width: $max-width-retina;
  margin: 0 auto;

  p.title {
    font-family: $primary-font;
    font-size: $selectedwork-title-font-3X;
    margin-bottom: 1rem;
    font-weight: 700;
  }

  .workCard {
    font-family: $secondary-font;
    padding: 4rem;
    margin-bottom: 2rem;
    background-size: cover;
    background-position: center center;
    transition: 0.1s linear;
    border-radius: 0px;
    cursor: pointer;
    &:hover{
      // border-radius: 15px;
      box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.25);
    }

    .logoWrapper {
      margin-bottom: 6rem;

      img {
        width: 100px;
      }
    }

    .contentWrapper {
      max-width: 50%;

      p.sno {
        margin: 0px 0px 10px 0px;
        font-family: $primary-font;
      }

      .title {
        margin: 0px 0px 10px 0px;
        opacity: 1;
      }

      .desc {
        margin: 0px 0px 10px 0px;
      }

      a {
        color: white;
        text-decoration: none;
      }

      ul.taglist {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0px;
        width: 50%;
        margin: 0px 0px 35px 0px;

        li.tag {
          background-color: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(0, 0, 0, 0.15);
          margin-right: 0.75rem;
          margin-bottom: 0.75rem;
          color: $light-grey;
          padding: 0.4rem 1rem;
          font-family: $secondary-font;
          border-radius: 5rem;
          font-size: 0.7rem;
          font-weight: 600;
        }
      }

      .ctaButton {
        font-family: $primary-font;
        font-size: 0.8rem;
        padding: 0.8rem 1.2rem;
        background-color: rgba(0, 0, 0, 0.15);
        border: 1px solid rgba(255, 255, 255, 1);
        transition: 0.1s linear;

        &:hover {
          background-color: #f8f8f8;
          color: #363636;
          border: 1px solid rgba(255, 255, 255, 0);
        }
      }
    }
  }

}

@media screen and (max-width: 1440px) {
  #selectedWork {
    max-width: $max-width-regular;
    margin: 0 auto;
  
    p.title {
      font-family: $primary-font;
      font-size: $selectedwork-title-font-2X;
      margin-bottom: 1rem;
    }
  
    .workCard {
      font-family: $secondary-font;
      padding: 2.5rem 2rem;
      margin-bottom: 2rem;
  
      .logoWrapper {
        margin-bottom: 4rem;
  
        img {
          width: 80px;
        }
      }
  
      .contentWrapper {
        max-width: 55%;
  
        p.sno {
          font-size: 0.9rem;
        }
  
        .title {
          font-size: $workcard-title-font-2X;
        }
  
        .desc {
          font-size: $workcard-desc-font-2X;
        }
  
        a {
          color: white;
          text-decoration: none;
        }
  
        ul.taglist {
          display: flex;
          flex-wrap: wrap;
          list-style: none;
          padding: 0px;
          width: 50%;
          margin: 0px 0px 35px 0px;
  
          li.tag {
            background-color: rgba(255, 255, 255, 0.1);
            border: 1px solid rgba(0, 0, 0, 0.15);
            margin-right: 0.75rem;
            margin-bottom: 0.75rem;
            color: $light-grey;
            padding: 0.2rem 0.4rem;
            font-family: $secondary-font;
            border-radius: 5rem;
            font-size: 0.6 rem;
            font-weight: 600;
          }
        }
  
        .ctaButton {
          font-family: $primary-font;
          font-size: 0.7rem;
          padding: 0.8rem 1.2rem;
          background-color: rgba(0, 0, 0, 0.15);
          border: 1px solid rgba(255, 255, 255, 1);
          transition: 0.1s linear;
  
          &:hover {
            background-color: #f8f8f8;
            color: #363636;
            border: 1px solid rgba(255, 255, 255, 0);
          }
        }
      }
    }
  
  }
}


@media screen and (max-width: 768px) {
  #selectedWork {
    width: 90%;

    p.title {
      font-size: 1.1rem;
      color: $primary-color;
    }

    .workCard {
      padding: 0rem;
      margin-bottom: 2rem;

      .logoWrapper {
        margin-bottom: 2rem;

        img {
          width: 75px;
        }
      }

      .contentWrapper {
        max-width: 100%;

        .project-image-mobile {
          img {
            width: 100%;
          }
        }

        .mobileTitleWrap {
          display: flex;
          margin: 0px 0px 10px 0px;

          p {
            font-size: 0.9rem;
          }

          p.sno {
            margin: 0px 5px 0px 0px;
            font-family: $primary-font;
            color: $primary-color;
          }

          p.title {
            margin-bottom: 0px;
            color: black;
          }
        }

        .desc {
          font-size: 0.8rem;
        }

        a {
          color: white;
          text-decoration: none;
        }

        ul.taglist {
          width: 100%;
          margin-bottom: 10px;

          li.tag {
            background-color: $off-white;
            border: 0px solid rgba(0, 0, 0, 0.15);
            color: #000;
            padding: 0.3rem 0.6rem;
            font-size: 0.6rem;
          }
        }
        .ctaButton {
          padding: 0.8rem 2rem;
          background-color: #000;
          border: 0px solid rgba(255, 255, 255, 1);
          font-size: 1rem;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  .divider {
    margin-bottom: 1.6rem;
    opacity: 0.25;
  }
}
