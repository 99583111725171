header#header {
  max-width: $max-width-retina;
  margin: 0 auto;
  padding: 2rem 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo {
    color: $primary-color;
    a {
      font-family: $logo-font-new;
      font-family: $primary-font;
      font-size: 1.4rem;
      font-weight: 500;
      margin: 1rem 0rem;
      text-decoration: none;
      color: $primary-color;
    }
  }

  .navigation {
    display: flex;
    align-items: center;
    margin: 0rem;

    a {
      font-family: $primary-font;
      margin: 0rem;
      list-style: none;
      margin-left: 3rem;
      text-decoration: none;
      font-size: $navigation-font-3X;
      opacity: 0.5;
      padding: 1rem 0rem;
      font-weight: 500;
      transition: 0.2s linear;
      &:hover {
        opacity: 0.75;
      }
    }
    a.active {
      opacity: 1;
      font-weight: 700;
    }

    a.resume {
      background-color: transparent;
      color: $accent-color;
      opacity: 0.35;
      padding: 15px 25px;
      transition: 0.1s linear;
      border: 2px solid $accent-color;
      &:hover {
        background-color: $accent-color;
        opacity: 1;
        color: white;
        border: 2px solid transparent;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  header#header {
    max-width: $max-width-regular;
    .logo {
      a {
        // font-size: $header-logo-font-2X;
      }
    }
  
    .navigation {
      display: flex;
      align-items: center;
      margin: 0rem;
  
      a {
        font-family: $primary-font;
        font-size: $navigation-font-2X;
        margin-left: 2rem;
      }
  
      a.resume {
        background-color: #000000;
        color: white;
        opacity: 1;
        padding: 10px 20px;
        transition: 0.1s linear;
        &:hover {
          background-color: transparent;
          color: $accent-color;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  header#header {
    width: 90%;
    padding: 1rem;
    .logo {
      a {
        font-size: 1.4rem;
      }
    }
  }

  .backdrop {
    position: fixed;
    z-index: 98;
    background: rgba($color: #000000, $alpha: 0.35);
    // backdrop-filter: blur(2px);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out;

    &.show {
      display: block;
    }

    &.hide {
      display: none;
    }
  }

  .navigation.menuStateTogger {
    font-family: $primary-font;
  }

  .navigationFloat {
    position: fixed;
    z-index: 99;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba($color: #ffffff, $alpha: 0.8);
    width: 100%;
    transition: 0.1s ease-in-out;
    overflow: hidden;
    box-shadow: 0px 0px 25px rgba($color: #000000, $alpha: 0.15);
    backdrop-filter: blur(4px);
    &.open {
      // height: 40vh;
      height: 50px;
      display: flex;
      align-items:center;
      justify-content: flex-end;
    }
    img.menuIcon {
      width: 15px;
      margin-right: 3px;
      opacity: 0.75;
    }
    &.closed {
      height: 0vh;
    }

    div {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-evenly;
      flex: 1;
      padding: 0;

      a {
        font-family: $primary-font;
        text-decoration: none;
        padding: 1rem;
        color: #000;
        font-size: 0.8rem;
        font-weight: 500;
        opacity: 0.25;
      }
      a.active {
        opacity: 1;
      }

      a.resume {
        background-color: transparent;
        color: $primary-color;
        // opacity: 1;
        padding: 1rem;
      }
    }
  }
}
