#blogWrapper {
    .blogWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: $max-width;
        margin: 0 auto;
        height: 80vh;
        font-family: $primary-font;

        .blogTitle {
            max-width: 75%;
            color: #000;
            font-size: 3rem;
            text-align: center;
            margin-bottom: 20px;
        }

        .blogLink {
            text-decoration: none;
            background: $primary-color;
            color: white;
            padding: 0.8rem 2rem;
            font-weight: 500;
            margin-left: 0.9rem;
            font-size: 1.2rem;
            transition: 0.1s linear;
            &:hover {
                background: $off-white;
                color: $primary-color;
                padding: 0.8rem 2rem;
                letter-spacing: 2px;
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    #blogWrapper {
        .blogWrapper {
            max-width: $max-width-regular;
            height: 75vh;
            font-family: $primary-font;

            .blogTitle {
                font-size: 2rem;
            }

            .blogLink {
                font-weight: 400;
                font-size: 1rem;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #blogWrapper {
        .blogWrapper {
            width: 90%;
            height: 65vh;
            font-family: $primary-font;

            .blogTitle {
                font-size: 1.6rem;
            }

            .blogLink {
                font-weight: 400;
                font-size: 1.2rem;
            }
        }
    }
}