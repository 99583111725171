@media (prefers-color-scheme: dark) {
  html {
    body {
      hr {
        opacity: 0.25 !important;
      }
      .dark {
          p {
              color: $off-white;
            }
        #homeWrapper,
        #aboutWrapper,
        #case-study,
        #blogWrapper,
        #resumeWrap,
        #work {
          transition: 0.25s ease-in-out;
          background-color: #0d1b1e;
          color: $off-white;
        }
        .toggleTheme {
          background-color: $off-white;
          img {
            filter: invert(0);
          }
        }
        header#header {
          .logo {
            a {
              color: $off-white;
            }
          }
          .navigation {
            display: flex;
            align-items: center;
            margin: 0rem;
            a {
              color: $off-white;
            }
            a.resume {
              background-color: $off-white;
              color: #020202;
              &:hover {
                background-color: transparent;
                color: $accent-color;
              }
            }
          }
        }
  
        footer {
          background-color: lighten($color: #020202, $amount: 5);
          .footer {
            .contact-me {
              span {
                color: $accent-color;
              }
            }
            p.footerstripe {
            }
          }
        }
  
        #hero,
        #heroAbout {
          p.desc {
            color: rgba($color: $off-white, $alpha: 0.75);
          }
        }
  
        #iWorkWith.main-wrapper {
          .workWithCardWrapper {
              .workWithCard {
                background-color: $off-white;
                p.title {
                  color: $black;
              }
            }
          }
        }
      }
    }
  }
}